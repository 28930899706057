#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  justify-content: space-between;
  align-items: center;
  background: #111827;
  overflow: auto;
  padding-inline: 20px;
  height: 70px;
  border-bottom: 1px solid #1e293b;
}

.site-layout-background .top-heading {
  margin: 0;
  color: #cbd5e1;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 15px;
}

main {
  padding-top: 20px !important;
}

.ant-form-item-control-input-content {
  display: flex;
}

.ant-table {
  overflow: auto;
}

.ant-table::-webkit-scrollbar{
  display: none;
}

.ant-menu-item{
  margin: 0 !important;
  background-color: #0f172a;
  font-size: 16px;
  padding-block: 24px !important;
}

.ant-menu-item:hover{
  background-color: #334155 !important;
}

.ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark, .ant-menu.ant-menu-dark .ant-menu-sub{
  background-color: #161b22 !important;
}

.ant-layout.ant-layout-has-sider {
  height: 100vh;
}

.ant-layout-sider {
  position: fixed;
  top: 0;
  left: 0;
  width: 5vw !important;
  z-index: 10;
  height: 100vh;
  background-color: #020617 !important;
  border-right: 1px solid #1e293b ;
  transform: translateX(-100%);
}

.menu-toggle {
  transform: translateX(0);
}

.menu-btn {
  position: absolute;
  top: 17px;
  right: -40px;
  width: 40px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  padding-inline: 10px;
}

.ant-message-notice-content {
  background-color: #0d1117 !important;
  color: #fff !important;
  border-radius: 5px;
}
.ant-table-thead > tr > th {
  background-color: #0d1117 !important;
  color: #fff !important;
  border-radius: 5px;
}
.ant-table-tbody {
  background-color: #161b20 !important;
  color: #fff !important;
  border-radius: 5px;
}
.ant-table-cell-row-hover{
  background-color: #2d3741 !important;
}

.header .ant-form-vertical{
  flex-direction: row !important;
}

.ant-form-vertical{
  display: flex;
  flex-direction: column;
  gap: 2px !important;
}

.ant-form-item{
  border-radius: 6px !important;
  overflow: hidden;
}

.ant-select-selector {
  height: 35px !important;
}

.ant-select-arrow svg{
  color: #09090b !important;
  height: 10px !important;
}

.item-list-outer .ant-card{
  width: 100% !important;
  background-color: #1e293b !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  transition: 300ms ease-in-out;
}

.item-list-outer .ant-card:hover{
  background-color: #334155 !important;
}

.item-list-outer .ant-card-body{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.anticon svg {
  height: 20px;
  width: 20px;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: white;
}

.ant-layout-header {
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-item {
  padding-left: 20px;
  padding-right: 30px;
  cursor: pointer;
}
.cart-item p {
  margin-top: 10px;
  font-weight: bold;
}

.ant-card .ant-btn{
  background-color: #1890ff !important;
  color: #ffffff;
  font-size: 16px;
  width: 100% !important;
  border: none;
  border-radius: 5px !important;
}

.ant-card .ant-btn:is(:hover,:focus){
  color: #ffffff;
  background-color: #15803d !important;
}

.ant-empty-description {
  color: #f8fafc;
}

.ant-table-cell {
  background-color: transparent !important;
}

.ant-table-thead > tr > th {
  border-radius: 0 !important;
  background-color: #0a0a0a !important;
}

.ant-select {
  width: 250px !important;
}

.ant-select-dropdown{
  border-radius: 5px !important;
  padding: 0 !important;
}

@media (min-width: 800px) {
  .ant-layout-sider {
    position: relative;
    transform: translateX(0);
  }

  .menu-btn{
    display: none;
  }
}

/* Styles for desktops */
@media (min-width: 1024px) {

}