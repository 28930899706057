#invoice-POS {
  width: 90%;
  margin: 0 auto;
}

h1 {
  font-size: 1.5em;
  color: #ffffff;
}
h2 {
  font-size: 1.8em;
  font-weight: 700;
}

.info h2 {
  letter-spacing: -0.8px;
  margin-top: 40px;
}

.info p {
  font-size: 10px;
  margin-top: -5px;
  font-weight: 600;
}

h3 {
  font-size: 1.2em;
  color: #ffffff;
  font-weight: 300;
  line-height: 2em;
}
p {
  font-size: 12px;
  color: #666;
  line-height: 1.2em;
}

.service p {
  font-size: 12px;
}

#top,
#mid,
#bot {
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #e4e4e4;
}

#top {
  min-height: 70px;
}

#bot {
  min-height: 50px;
}

.info {
  display: block;
  margin-left: 0;
}
.title {
  float: right;
}
.title p {
  text-align: right;
}
table {
  width: 100%;
  border-collapse: collapse;
}
/* td {
    //padding: 5px 0 5px 15px;
    //border: 1px solid #EEE
  } */
.tabletitle {
  /* //padding: 5px; */
  font-size: 0.5em;
  background: #eee;
}
.service {
  border-bottom: 1px solid #eee;
}
.item {
  width: 24mm;
}
.itemtext {
  font-size: 0.5em;
}

#legalcopy {
  margin-top: 5mm;
}
