html{
  background-color: #020617;
}

body {
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
  background-color: #1e293b;
}

main {
  overflow-x: hidden !important;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-radius: 6px !important;
  height: 35px !important;
  color: #ffffff !important;
  border: none;
  transition: 200ms ease-in-out;
}

input {
  height: 35px !important;
  padding: 4px 8px;
  border: none;
}

input:focus{
  outline: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #334155; 
}

::-webkit-scrollbar-thumb {
  background: #589eff;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2f81f7;
}

.loader {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.header {
  display: grid;
  gap: 10px;
  margin-bottom: 20px;
}

.header h2 {
  color: #ffffff;
  flex-grow: 1;
  font-weight: 700;
  font-family: "Krungthep",sans-serif;
  margin: 0;
  margin-right: 20px;
}

.add-category{
  display: flex;
  width: 100%;
  align-items: center;
  gap: 5px;
  border: none;
  background-color: #334155;
  font-size: 16px;
}

.add-category:is(:focus,:hover){
  background-color: #2f81f7;
}

.searchbar {
  display: flex;
  width: 100%;
  background-color: #e2e8f0;
  border-radius: 6px;
  overflow: hidden;
}

.search-btn {
  background-color: #2f81f7;
  border-radius: 6px !important;
  padding: 0 20px;
}

.search-field{
  width: 100%;
  background-color: #e2e8f0;
}

.filter{
  border-radius: 6px;
  background-color: #2f81f7 !important;
  overflow: hidden !important;
}

.brand-quantity {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #cbd5e1;
}

.filter-btn{
  display: flex !important;
  align-items: center;
  gap: 5px;
  font-size: 16px !important;
}

.item-button button {
  margin-top: 20px;
  width: 200px;
  background-color: rgb(9, 78, 60);
  color: white;
  border-radius: 8px;
}

.invoice-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding-inline: 25px;
  border: none;
  background-color: #2f81f7;
  color: #cbd5e1;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.spinner-border {
  height: 50px;
  width: 50px;
}
.test-creds{
  font-size: 12px;
}

.category {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
  padding-inline: 20px;
  margin-right: 10px;
  border: 1px solid #8b949e;
  border-radius: 5px;
  background-color: #1e293b;
  transition: background-color 0.2s ease-in-out, scale 0.1s ease-in-out;
}

.category:hover {
  background-color: #020617;
  cursor: pointer;
}
.category-active:hover {
  background-color: #589eff;
}

.category h4 {
  color: white !important;
  font-size: 14px;
}

.category-active {
  background-color: #2f81f7;
  color: #fff;
}

.category-active img {
  filter: brightness(0) invert(1);
}


.register {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: #1e293b;
  padding: 20px;
}

.register input {
  width: 100% !important;
  height: 40px !important;
  border: 2px solid #1e293b !important;
  border-radius: 8px;
  background-color: #e5f1ff;
  padding: 5px 15px !important;
  margin-bottom: 5px;
  font-size: 16px;
}

.register input:focus{
  outline: none !important;
}

.register .ant-form-item {
  margin: 0 !important;
}

.regsiter-form {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  background-color: #334155;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 25px;
}

.register h1 {
  font-size: 2rem;
  text-align: center;
}

.regsiter-form label {
  color: #cbd5e1 !important;
}

.regsiter-form p {
  font-size: 16px;
  color: #cbd5e1;
}

.regsiter-form button {
  border-radius: 8px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  height: 40px !important;
  font-size: 20px;
}

.login-outer {
  display: flex;
  background-color: #0f172a;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.login-outer div {
  flex-grow: 1;
}

.login-banner {
  background-color: #1e293b;
}

.search-icon{
  height: 35px;
  background-color: #2f81f7;
  border: none;
  padding-top: 5px;
  padding-inline-end: 15px;
  text-align: center;
}

.overlay{
  position: absolute;
  background-color: #0e131c81;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.selling-price{
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  background-color: #334155;
  padding: 10px;
  border-radius: 6px;
  transform: translate(-50%,-50%);
  scale: 0.9;
  opacity: 0;
  visibility: hidden;
  transition: scale 100ms ease-in-out;
}

.selling-price.active{
  scale: 1;
  opacity: 1;
  visibility: visible;
}

.selling-price button {
  border: none;
  border-radius: 6px;
  width: 100%;
  height: 35px;
  background-color: #2f81f7;
  font-weight: 600;
}

.price-input{
  font-size: 16px;
  border: none;
  padding: 2px 8px;
  max-height: 30px;
  border-radius: 5px;
}

.price-input:focus{
  border: none;
  outline: none;
}

.previous-stock-btn:is(:hover,:focus){
  background-color: #589eff;
  color: #020617;
}

.category-form{
  top: 100%;
  right: 0;
  width: 100%;
  min-width: 300px;
  background-color: #334155;
  margin-top: 0;
  padding: 10px;
  border-radius: 8px;
  opacity: 0;
  z-index: 10;
  visibility: hidden;
  transition: 100ms ease-in-out;
}

.category-form.active{
  visibility: visible;
  opacity: 1;
  margin-top: 10px;
}

.category-form input,
.category-form button {
  border: none;
  border-radius: 6px;
  width: 100%;
}
.category-form input:is(:hover,:focus) {
  outline: none;
}

.category-form input {
  background-color: #e2e8f0;
  padding-inline: 8px;
  padding-block: 6px;
  font-size: 14px;
}

.category-form button {
  background-color: #2f81f7;
  height: 35px;
  margin-top: 10px;
  margin-bottom: 5px;
  transition: 100ms ease-in-out;
  font-weight: 600;
  font-size: 16px;
}

.category-form button:is(:hover,:focus) {
  background-color:#589eff;
}

.category-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #64748b;
  border-radius: 9px;
  border: 2px solid #1e293b;
}

.category-item p {
  margin: 0;
  font-size: 16px;
  text-transform: capitalize;
  color: #f8fafc;
  padding-left: 10px;
}

.category-count{
  font-weight: 600;
  margin-left: 5px;
  margin-bottom: 5px;
  color: #cbd5e1;
}

.select-field{
  width: 100px;
  height: 35px;
  margin-top: -10px;
  border-radius: 6px;
}

.category-outer{
  display: flex;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  margin-bottom: 20px;
  gap: 8px;
}

.category-btn{
  background-color: #1e293b;
  padding-inline: 20px;
  text-transform: capitalize;
  border: 1px solid #cbd5e1;
  transition: 200ms ease-in-out;
}

.category-btn:hover{
  background-color: #020617;
}

.category-btn.active{
  background-color: #2f81f7;
}

.category-delete-btn{
  background-color: #1e293b !important;
  padding-inline: 10px;
  margin: 0 !important;
  width: max-content !important;
}

.category-delete-btn:hover{
  background-color: #ef4444 !important;
}

.item-list-outer{
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding-bottom: 20px;
}

.card-title {
  color: #ffffff;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.item-details p {
  line-height: 1.2;
  color: #ffffff;
  margin: 0;
  font-size: 16px;
  text-transform: capitalize;
}

.item-details p b {
  font-weight: 600;
  color: #cbd5e1;
}

.bill-popup{
  position: absolute;
  top: 60%;
  left: 50%;
  min-height: 80vh;
  padding: 10px;
  background-color: #cbd5e1;
  transform: translate(-50%,-50%);
  visibility: hidden;
  opacity: 0;
  transition: 200ms ease-in-out;
}

.bill-popup.active{
  top: 90%;
  opacity: 1;
  visibility: visible;
}

.bill-popup button{
  border: none;
  background-color: #2f81f7;
  height: 35px;
  width: 100%;
  border-radius: 5px;
  padding-inline: 20px;
  font-size: 16px;
  font-weight: 600;
}

.bill .content {
  background-color: #ffffff;
  min-width: 50vw;
}

.content-head-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em;
  font-size: 12px;
  font-family: "Krungthep",sans-serif;
}

.content-head{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  font-size: 12px;
  font-family: "Krungthep",sans-serif;
}

.content-head .title {
  color: #000000;
  font-family: "Britannic Bold",sans-serif;
  font-size: 50px;
  margin: 0;
}

.content-head .sub-title {
  font-size: 18px;
  margin: 0;
}
.content-head .address {
  font-size: 14px;
  margin: 0;
}
.content-head .contact {
  font-size: 12px;
  margin: 0;
  margin-top: 10px;
}

.content .customer-details{
  margin-inline: 2em;
}

.content .customer-details p {
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Krungthep",sans-serif;
  text-transform: capitalize;
}

.content .invoice-table {
  margin-inline: 2em;
  margin-block: 3em;
}

.content .invoice-table th,
.content .invoice-table tr,
.content .invoice-table td{
  padding: 0.5rem;
  text-align: center;
}

.content .invoice-table th {
  font-size: 10px;
  font-family: "Krungthep",sans-serif;
  background-color: #d2d2d2;
}

.content .invoice-table tr:nth-child(2n) {
  background-color: #e8e8e8;
}

.content .total {
  margin-inline: 2em;
  margin-bottom: 2em;
  display: flex;
  justify-content: space-between;
}

.content .total p{
  font-size: 16px;
  font-family: "Krungthep",sans-serif;
}

.content .logo {
  margin-inline: 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
}

.content .logo p {
  font-size: 12px;
  padding-inline: 5em;
  margin-bottom: 5em;
  font-family: "Krungthep",sans-serif;
}

.content .footer {
  margin-inline: 2em;
  margin-top: 3em;
  text-align: center;
  padding-block: 2em;
  font-family: "Krungthep",sans-serif;
}

.content p {
  color: #44403c !important;
}

.logout-popup{
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #334155;
  border-radius: 6px;
  padding: 20px;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.logout-popup h4 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #f8fafc;
}

.logout-popup .grp-btn {
  display: flex;
  justify-content: center;
  gap : 8px
}

.logout-popup button {
  font-size: 16px;
  line-height: 1;
}

.action-btn-grp{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.action-btn{
  background-color: #1e293b;
  border: 2px solid #475569;
}

.action-btn:is(:focus,:hover){
  background-color: #475569;
}

.stock-login-form-outer {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  gap: 10px;
}

.stock-login-form-outer h1 {
  font-size: 2rem;
  font-weight: 600;
}

.stock-login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background-color: #1e293b;
  padding: 20px;
  border-radius: 6px;
  gap: 10px;
}

.stock-login-form label {
  color: #f8fafc;
}

.stock-login-form input {
  border-radius: 6px;
  font-size: 16px;
  border: 2px solid #64748b;
  background-color: #f8fafc;
}

.stock-login-form button {
  background-color: #2f81f7;
  margin-block: 5px;
}

.stock-login-form p {
  color: #ef4444 !important;
  text-align: center;
}

.add-item-form {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 20px;
  margin-top: -20px;
  background-color: #334155;
  border-radius: 6px;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);
  transition: 200ms ease-in-out;
}

.add-item-form.active{
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.add-item-form label{
  color: #f8fafc;
  font-weight: 500;
}

.add-item-form input{
  border-radius: 6px;
  margin-bottom: 8px;
  background-color: #e2e8f0;
}

.add-item-form select {
  border-radius: 6px;
  height: 35px;
  padding-inline: 5px;
  background-color: #e2e8f0;
  cursor: pointer;
  margin-bottom: 8px;
}

.add-item-form select:focus{
  outline: none;
}

.add-item-form button {
  background-color: #2f81f7;
  font-size: 18px;
  font-weight: 600;
  margin-top: 8px;
}

.quantity-counter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-counter button {
  height: 20px !important;
  width: 20px;
  padding: 5px;
  border-radius: 50% !important;
  background-color: #334155;
  color: #f8fafc !important;
}

.edit-price-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

@media (min-width: 600px) {
  .item-list-outer{
    grid-template-columns: repeat(2,1fr);
  }

  .ant-form > .filter-btn {
    width: 200px;
  }
}

/* Styles for desktops */
@media (min-width: 1024px) {
  .add-category {
    max-width: 200px;
  }

  .item-list-outer{
    grid-template-columns: repeat(3,1fr);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .searchbar {
    max-width: 300px;
  }
}